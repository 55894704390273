import MicroModal from 'micromodal';

export default class Modal {
    private static currentlyShownModalID: string = null;

    static show(modalID: string, onClose?: () => void) {
        if (this.currentlyShownModalID !== modalID) {
            this.close(this.currentlyShownModalID);
            MicroModal.show(modalID, {
                awaitCloseAnimation: true,
                disableFocus: true,
                onClose: (modal) => {
                    this.currentlyShownModalID = null;
                    onClose?.();
                },
            });
            this.currentlyShownModalID = modalID;
        }
    }

    static close(modalID: string) {
        if (modalID && this.currentlyShownModalID === modalID) {
            MicroModal.close(modalID);
            this.currentlyShownModalID = null;
        }
    }
}
